<div class="card" [ngClass]="cardClass" [@cardRemove]="cardRemove" #toAnimate>
  <!-- animates animatesInitMode="show" [animatesOnInit]="{useVisibility: false, disabled: true}" -->
  <div class="card-header" *ngIf="!hidHeader" [ngClass]="headerClass">
    <div class="reloadblock" *ngIf="moduleName == 'Job'">
      <!-- <button class="reload-btn" (click)="reload($event)">Reload</button> -->
      <button
        type="button"
        class="btn btn-primary"
        (click)="reload($event)"
        pTooltip="Click to reload a {{ module }}"
        tooltipPosition="top"
      >
       <i class="fa-solid fa-rotate-right"></i> RELOAD
      </button>
      <!-- <a class="btn button-cls btn-primary color" (click)='exportJob($event)' pTooltip="Click to Export"
        tooltipPosition="top">Export</a> -->
    </div>

    <div *ngIf="moduleName == 'Questions'">
      <button
        type="button"
        class="btn btn-primary"
        (click)="reload($event)"
        pTooltip="Click to reload a {{ module }}"
        tooltipPosition="top"
      >
      <i class="fa-solid fa-rotate-right"></i> RELOAD
      </button>

      <button
        type="button"
        class="btn marginleft"
        (click)="createQuestion($event)"
        pTooltip="Click to add a question"
        tooltipPosition="top"
        *ngIf="checkPermissions('create', 'Form Question')"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>
    </div>

    <div *ngIf="moduleName == 'Sections'">
      <button
        type="button"
        class="btn btn-primary"
        (click)="reload($event)"
        pTooltip="Click to reload a {{ module }}"
        tooltipPosition="top"
      >
      <i class="fa-solid fa-rotate-right"></i> RELOAD
      </button>

      <button
        type="button"
        class="btn marginleft"
        (click)="createQuestion($event)"
        pTooltip="Click to add a question"
        tooltipPosition="top"
        *ngIf="checkPermissions('create', 'Form Sections')"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>
    </div>

    <div *ngIf="moduleName == 'Builders'">
      <button
        type="button"
        class="btn btn-primary"
        (click)="reload($event)"
        pTooltip="Click to reload a {{ module }}"
        tooltipPosition="top"
      >
      <i class="fa-solid fa-rotate-right"></i> RELOAD
      </button>

      <button
        type="button"
        class="btn marginleft"
        (click)="createForm($event)"
        pTooltip="Click to add a form"
        tooltipPosition="top"
        *ngIf="checkPermissions('delete', 'Form Builder')"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>
    </div>
    <div *ngIf="moduleName == 'formType'">
      <button
        type="button"
        class="btn btn-primary"
        (click)="formTypeReloading($event)"
        pTooltip="Click to reload a {{ module }}"
        tooltipPosition="top"
      >
      <i class="fa-solid fa-rotate-right"></i> RELOAD
      </button>

      <button
        type="button"
        class="btn marginleft"
        (click)="createFormType($event)"
        pTooltip="Click to add a {{ module }}"
        tooltipPosition="top"
        *ngIf="checkPermissions('create', 'Form Component')"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>
    </div>

    <div
      *ngIf="moduleName == 'Vehicles' && checkPermissions('create', 'Vehicles')"
    >
      <!-- <span (click)='createVehicle($event)'>
              <i class="fa fa-plus-circle fa-2x"></i>
            </span> -->
      <button
        type="button"
        class="btn btn-primary"
        (click)="createVehicle($event)"
        pTooltip="Click to add a {{ module }}"
        tooltipPosition="top"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>
    </div>
    <div
      *ngIf="
        moduleName == 'Vehicle Types' &&
        checkPermissions('create', 'Vehicle Types')
      "
    >
      <!-- <span (click)='createVehicle($event)'>
              <i class="fa fa-plus-circle fa-2x"></i>
            </span> -->
      <button
        type="button"
        class="btn btn-primary"
        (click)="createVehicle($event)"
        pTooltip="Click to add a {{ module }}"
        tooltipPosition="top"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>
    </div>
    <div
      *ngIf="
        moduleName == 'Waste Category' &&
        checkPermissions('create', 'Waste Category')
      "
    >
      <button
        type="button"
        class="btn btn-primary"
        (click)="createVehicle($event)"
        pTooltip="Click to add a {{ module }}"
        tooltipPosition="top"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>
    </div>
    <div
      *ngIf="
        moduleName == 'Customers' && checkPermissions('create', 'Customers')
      "
    >
      <!-- <span (click)='createVehicle($event)'>
              <i class="fa fa-plus-circle fa-2x"></i>
            </span> -->
      <button
        type="button"
        class="btn btn-primary"
        (click)="createVehicle($event)"
        pTooltip="Click to add a {{ module }}"
        tooltipPosition="top"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>
    </div>
    <div
      *ngIf="
        moduleName == 'Customer Divisions' &&
        checkPermissions('create', 'Customer Divisions')
      "
    >
      <!-- <span (click)='createVehicle($event)'>
              <i class="fa fa-plus-circle fa-2x"></i>
            </span> -->
      <button
        type="button"
        class="btn btn-primary"
        (click)="createVehicle($event)"
        pTooltip="Click to add a {{ module }}"
        tooltipPosition="top"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>
    </div>
    <div *ngIf="moduleName == 'Staff'">
      <button
        type="button"
        class="btn btn-primary"
        (click)="createStaff($event)"
        pTooltip="Click to add a {{ module }}"
        tooltipPosition="top"
        *ngIf="checkPermissions('create', 'Staff')"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>

      <button
        type="button"
        class="btn marginleft button-cls"
        (click)="importStaff($event)"
        pTooltip="Click to Import Staff"
        tooltipPosition="top"
        *ngIf="checkPermissions('create', 'Staff')"
      >
        Import
      </button>

      <a
        class="btn button-cls btn-primary color"
        pTooltip="Click to Export"
        tooltipPosition="top"
        (click)="exportStaff($event)"
        >Export</a
      >
    </div>
    <div *ngIf="moduleName == 'Admin'">
      <button
        type="button"
        class="btn btn-primary"
        (click)="createStaff($event)"
        pTooltip="Click to add a {{ module }}"
        tooltipPosition="top"
        *ngIf="checkPermissions('create', 'Admin')"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>

      <button
        type="button"
        class="btn marginleft button-cls"
        (click)="importStaff($event)"
        pTooltip="Click to Import Staff"
        tooltipPosition="top"
        *ngIf="checkPermissions('create', 'Admin')"
      >
        Import
      </button>

      <a
        class="btn button-cls btn-primary color"
        pTooltip="Click to Export"
        tooltipPosition="top"
        (click)="exportStaff($event)"
        >Export</a
      >
    </div>
    <div *ngIf="moduleName == 'disposal'">
      <!-- <span (click)='createVehicle($event)'>
              <i class="fa fa-plus-circle fa-2x"></i>
            </span> -->
      <button
        type="button"
        class="btn btn-primary"
        (click)="createDisposal($event)"
        pTooltip="Click to add a {{ module }}"
        tooltipPosition="top"
        *ngIf="checkPermissions('create', 'Disposal Sites')"
      >
      <i class="fa-solid fa-plus"></i> ADD
      </button>
      <button
        type="button"
        class="btn marginleft button-cls"
        (click)="import($event)"
        pTooltip="Click to Import Prices"
        tooltipPosition="top"
        *ngIf="
          checkPermissions('create', 'Disposal Sites') ||
          checkPermissions('edit', 'Disposal Sites')
        "
      >
        Import
      </button>
      <a
        class="btn button-cls btn-primary color"
        (click)="export($event)"
        pTooltip="Click to Export"
        tooltipPosition="top"
        >Export</a
      >

      <!-- <a  class="btn btn-primary button-cls " (click)='import($event)' pTooltip="Click to reload a {{module}}" tooltipPosition="top">Import</a> -->
    </div>

    <h5 *ngIf="!customHeader">{{ cardTitle }}</h5>
    <p *ngIf="!customHeader && cardCaption" [ngClass]="captionClass">
      {{ cardCaption }}
    </p>
    <div class="card-header-right" *ngIf="this.options && !customHeader">
      <div class="btn-group card-option dropdown" ngbDropdown placement="auto">
        <!-- <button type="button" class="btn dropdown-toggle btn-icon" ngbDropdownToggle>
          <i class="feather icon-more-horizontal"></i>
        </button> -->
        <ul
          class="list-unstyled card-option dropdown-menu dropdown-menu-right"
          ngbDropdownMenu
        >
          <li
            class="dropdown-item full-card"
            (click)="fullCardToggle(toAnimate, '', true)"
          >
            <a href="javascript:"
              ><span
                ><i class="feather" [ngClass]="fullIcon"></i>
                {{
                  this.cardClass === "full-card" ? "Restore" : "Maximize"
                }}</span
              ></a
            >
          </li>
          <li
            class="dropdown-item minimize-card"
            (click)="collapsedCardToggle()"
          >
            <a href="javascript:"
              ><span
                ><i class="feather" [ngClass]="collapsedIcon"></i>
                {{ this.collapsedCard === "collapsed" ? "Expand" : "Collapse" }}
              </span>
              <span style="display: none"
                ><i class="feather icon-plus"></i></span
            ></a>
          </li>
          <li class="dropdown-item reload-card" (click)="cardRefresh()">
            <a href="javascript:"
              ><i class="feather icon-refresh-cw"></i> Reload</a
            >
          </li>
          <li class="dropdown-item close-card" (click)="cardRemoveAction()">
            <a href="javascript:"><i class="feather icon-trash"></i> Remove</a>
          </li>
        </ul>
      </div>
    </div>
    <ng-content *ngIf="customHeader" select=".app-card-header"></ng-content>
  </div>
  <div
    [@collapsedCard]="collapsedCard"
    *ngIf="this.options; else subMenuContent"
  >
    <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
  </div>
  <ng-template #subMenuContent>
    <div class="card-body" [ngClass]="blockClass">
      <ng-content></ng-content>
    </div>
  </ng-template>
  <div *ngIf="isCardFooter" class="card-footer" [ngClass]="footerClass">
    <ng-content select=".app-card-footer"></ng-content>
  </div>
  <div class="card-loader" *ngIf="loadCard">
    <i class="pct-loader1 anim-rotate"></i>
  </div>
</div>
