import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { DisposalSitesService } from "./disposal-sites.service";
import { ConfirmationService } from "primeng/api";
import { MessageService } from "primeng/api";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { DialogService } from "primeng/dynamicdialog";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { Table } from "primeng/table/table";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  FormBuilder,
} from "@angular/forms";

import { FileUploadValidators } from "@iplab/ngx-file-upload";
import { JobService } from "../job/job-data.service";
import * as saveAs from "file-saver";

@Component({
  selector: "app-disposal-sites",
  templateUrl: "./disposal-sites.component.html",
  styleUrls: ["./disposal-sites.component.scss"],
})
export class DisposalSitesComponent implements OnInit {
  @ViewChild("dt", { static: false }) table: Table;
  profileForm: FormGroup;
  pageDisposalIndex;
  showLink = false;
  linkData: any;
  disposalSiteList = [];
  selectedDisposal: any;
  showAdd = false;
  showimport = false;
  companyID: any;
  isAcumen: any;
  isUpdate = false;
  isView = false;
  showAlertText = false;
  viewData: any;
  wasteCategoryList = [];
  selectedCategory: any;
  cols = [
    // {
    //     header: 'Big change Id',
    //     field: 'Id'
    // },
    // {
    //     header: 'Company ID',
    //     field: 'companyId'
    // },
    // {
    //   header: 'Company Name',
    //   field: 'companyName'
    // },
    // {
    //   header: 'Disposal Site Name',
    //   field: 'disposalSiteName'
    // },
    {
      header: "Display Name",
      field: "Name",
    },
    {
      header: "Type",
      field: "typeName",
    },
  ];
  searchArr = [];
  disposalData: any;
  exportDisposalLocations;
  adminUserList = [];
  showLoader = true;
  selectedVehicle: any;
  vehicleTypeList = [];
  token: any;
  constructor(
    private fb: FormBuilder,
    private service: DisposalSitesService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private confirmDialogService: ConfirmationService,
    private router: Router,
    private jobService: JobService
  ) {
    this.token = localStorage.getItem("token");

    this.exportDisposalLocations = `${environment.apiURL}disposallocation/export-disposalLocations`;
  }

  ngOnInit() {
    this.clearIndex();
    this.companyID = localStorage.getItem("companyID");
    this.isAcumen = localStorage.getItem("isAcumen");
    this.pageDisposalIndex = parseInt(
      localStorage.getItem("pageDisposalIndex")
    );
    //  this.setPage(2);
    if (this.pageDisposalIndex) {
      localStorage.removeItem("pageDisposalIndex");
    }
    this.fetchDisposalList();
    if (this.cols.length > 0) {
      this.cols.forEach((element) => {
        this.searchArr.push(element.field);
        console.log(this.searchArr);
      });
    }
  }
  clearIndex() {
    localStorage.removeItem("jobID");
    localStorage.removeItem("pageJobIndex");
    localStorage.removeItem("pageWasteIndex");
    localStorage.removeItem("pageReportIndex");
    localStorage.removeItem("pageInspectionReportIndex");
  }
  callList(event) {
    console.log("call list");
    this.showAdd = false;
    this.showimport = false;
    this.isView = false;
    this.disposalData = {};
    this.cdr.detectChanges();
    this.fetchDisposalList();
  }
  import(event) {
    console.log("import");
    this.showimport = true;
    this.cdr.detectChanges();
  }
  createDisposal(event) {
    console.log("create staff called");

    if (this.showAdd == true) {
      this.showAdd = false;
      this.isView = false;
      this.disposalData = {};
      setTimeout((e) => {
        this.showAdd = true;
      }, 1000);
    } else if (this.showLink == true) {
      this.showLink = false;
      this.linkData = {};
      setTimeout((e) => {
        this.showAdd = true;
      }, 1000);
    } else {
      this.showAdd = true;
    }
    this.cdr.detectChanges();
  }
  editDisposal(event, data) {
    this.service.getDisposalDetails(data.Id).subscribe((response) => {
      console.log("user  details", response);
      this.disposalData = response.data;
      this.showAdd = true;
      console.log("user  details", this.disposalData);

      this.cdr.detectChanges();
      // editData = response.data[0];
      // this.showLoader = false;
      // const ref = this.dialogService.open(AddEditStaffComponent, {
      //     data: {
      //         viewData: editData,
      //         isUpdate: true
      //     },
      //     header: 'Edit Staff Details',
      //     width: '600px',
      // });

      // ref.onClose.subscribe((car) => {
      //     if (car) {
      //         // this.messageService.add({severity:'info', summary: 'Staff Updated successfully'});
      //      this.fetchDisposalList();
      //     }
      // });
    });
  }
  listPrice(event, data) {
    console.log("data", data);
    if (this.pageDisposalIndex != 1) {
      let pageNum = this.pageDisposalIndex.toString();
      setTimeout((e) => {
        localStorage.setItem("pageDisposalIndex", pageNum);
      }, 1000);
    }
    this.router.navigate(["disposalsites/listPrices/" + data.Id]);
  }
  listCustomer(event, data) {
    this.service.getDisposalDetails(data.Id).subscribe((response) => {
      this.linkData = response.data;
      this.showLink = true;
      this.cdr.detectChanges();
    });
  }
  onPageEvent(event) {
    console.log("event", event);
    this.pageDisposalIndex = event.first / event.rows + 1;
  }
  setPage(n: number) {
    this.table.reset();
    let paging = {
      first: (n - 1) * this.table.rows,
      rows: this.table.rows,
    };
    console.log("table values", paging);
    this.table.first = paging.first;
    this.table.rows = paging.rows;
  }

  exportData(event) {
    this.showLoader = true;
   this.service.ExportDisposalLocations().subscribe( 
    (res) => {
    if (res.data && res.data.length > 0) {
      this.downloadCSV(res.data, 'disposal Sites')
     // this.showLoader = false;
    } else{
     // this.showLoader = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Export',
        detail: 'Sorry, no records were found. Please adjust your search criteria and try again.'
      });
    }
   },
   (err) => {
   // this.showLoader = false;
    this.messageService.add({
      severity: 'success',
      summary: 'Export',
      detail: 'Sorry, an error has occured.'
    });
   })
  }


  async downloadCSV(data: any, fileName: string = "disposal Sites") {
    try {
      const date = `${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}_${new Date().getHours()}:${new Date().getMinutes()}`;
      const exportFileName = `${fileName}_${date}.csv`;
  
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      // const header = Object.keys(data[0]);
      // const header = _.keys(data)
      const header = Object.keys(Object.assign({}, ...data));
      // console.log(header);
      let csv = data.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(header.join(","));
      let csvArray = csv.join("\r\n");
      var blob = new Blob([csvArray], { type: "text/csv" });
      saveAs(blob, exportFileName);
    } catch (error) {
      console.log(error)
      this.messageService.add({
        severity: 'warning',
        summary: 'Export',
        detail: 'Sorry, an error has occured.'
      });
    }
    
  }

  importData(event) {
    const formData = new FormData();
    // formData.append('name', this.profileForm.get('name').value);
    // formData.append('profile', this.profileForm.get('profile').value);

    this.service.importDisposal(formData).subscribe(
      (res) => {},
      (err) => {}
    );
  }
  listEWCCode(event, data) {
    if (this.pageDisposalIndex != 1) {
      let pageNum = this.pageDisposalIndex;
      localStorage.setItem("pageDisposalIndex", pageNum);
    }
    this.router.navigate(["disposalsites/listcategory/" + data.Id]);
  }
  closeView(event) {
    this.showAlertText = false;
  }
  change(event, car) {
    console.log("data", car);
    let value: any = {};
    this.service.getDisposalDetails(car.Id).subscribe((response) => {
      let data = response.data;
      value.alert_text = data.alert_text ? data.alert_text : "";
      value.wasteTypeId = data.wasteTypeId;
      value.linked_waste_category = data.linked_waste_category;
      value.default_min_tonnage = data.default_min_tonnage
        ? data.default_min_tonnage
        : "";
      value.metal_slider = car.metal_slider ? car.metal_slider : false;
      value.active = car.active == true ? 1 : 0;
      value._id = data._id;
      console.log("value", value);

      this.update(value);
    });
  }
  update(value) {
    this.service.updateDisposalLocation(value).subscribe((data: any) => {});
  }

  viewDisposal(event, data) {
    
    this.service.getDisposalDetails(data.Id).subscribe((response) => {
      this.disposalData = response.data;
      this.disposalData.externalSiteId = data.externalSiteId
      this.isView = true;
      this.showAdd = true;
      this.cdr.detectChanges();
    });
  }
  deleteDisposal(id) {
    this.confirmDialogService.confirm({
      message: "Are you sure that you want to delete the Disposal Site?",
      accept: () => {
        this.service.deleteSite(id).subscribe(
          (data: any) => {
            if (data.status === 200) {
              this.messageService.add({
                severity: "success",
                summary: "Delete Disposal Site",
                detail: "Disposal Site details deleted successfully",
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Delete Disposal Site",
                detail: data.error.message,
              });
            }
            this.fetchDisposalList();
          },
          (err) => {
            console.log("error", err);
            this.messageService.add({
              severity: "error",
              summary: "Delete Disposal Site",
              detail: "Failed to delete the Disposal Site",
            });
            this.fetchDisposalList();
          }
        );
        //  });
      },
    });
  }
  fetchDisposalList() {
    const staffArr = [];
    this.service.fetchDisposalLocation().subscribe((response) => {
      console.log("staff  details", response);

      this.disposalSiteList = response.data;
      if (this.disposalSiteList.length > 0) {
        this.disposalSiteList.forEach((element) => {
          element.metal_slider = element.metal_slider
            ? element.metal_slider
            : false;
          element.active = element.active == 1 ? true : false;
        });
      }
      this.showLoader = false;
      if (this.pageDisposalIndex && this.pageDisposalIndex > 1) {
        console.log("page index", this.pageDisposalIndex);
        this.setPage(this.pageDisposalIndex);
      }
    });
  }
  closeAll(event) {
    this.showLink = false;
    this.linkData = {};
    this.cdr.detectChanges();
    this.fetchDisposalList();
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }
}
