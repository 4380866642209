import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AdminUserService } from "./demo/admin-users/admin-users.service";

// import { initializeApp } from "firebase/app";
// import { getAnalytics, logEvent } from "firebase/analytics";
// import { environment } from "src/environments/environment";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  msgs = [];

  title = "af-notification";
  message: any = null;
  //  app:any = initializeApp(environment.firebaseConfig);
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private adminUserService: AdminUserService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('triggered', event);
//  const analytics = getAnalytics(this.app);
//  logEvent(analytics, 'page_view', { page_path: event.urlAfterRedirects });
        // Add your code here to perform the desired actions
      }
    });
    this.translateService.setDefaultLang("en");

    const url = window.location.href
      .split(window.location.hostname)[1]
      .split("/")
      .slice(1)
      .join("/");
    console.log("url", url);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    if (window.location.pathname === "/") {
      let token = localStorage.getItem("token");
      if (token) {
        this.router.navigate["dashboard/analytics"];
      }
    }
    console.log("app called");
    if (localStorage.getItem("token")) {
      this.getUserDetails();
    }
    // this.requestPermission();
    // this.listen();
  }

  getUserDetails() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    console.log("userData", userData);
    this.adminUserService.getDetails(userData._id).subscribe((result: any) => {
      console.log(result);
      localStorage.setItem(
        "accessModule",
        JSON.stringify(result.data[0].accessModules)
      );
      localStorage.setItem("userData", JSON.stringify(result.data[0]));
    }),
      (error) => {
        console.log(error);
      };
  }

  // requestPermission() {
  //   const messaging = getMessaging();
  //   getToken(messaging, {
  //     vapidKey:
  //       "BOM7ljed_Zy1ookbQuxtTdCTgN-nYTR76PIbI_Ae6vQA4LZCUU2TXzLL8Br6vcfU0wnCVpFQXCwbP6um3SinSj0",
  //   })
  //     .then((currentToken) => {
  //       if (currentToken) {
  //         console.log("Hurraaa!!! we got the token.....");
  //         console.log(currentToken);
  //       } else {
  //         console.log(
  //           "No registration token available. Request permission to generate one."
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("An error occurred while retrieving token. ", err);
  //     });
  // }
  // listen() {
  //   const messaging = getMessaging();
  //   onMessage(messaging, (payload) => {
  //     console.log("Message received. ", payload);
  //     this.message = payload;
  //   });
  // }
}
