import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
@Injectable()
export class HttpLogInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    if (token) {
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(token);
      const expirationDate = helper.getTokenExpirationDate(token);
      const isExpired = helper.isTokenExpired(token);
      // if (isExpired) {
      //  // alert('token expired');
      //   this.redirectToLogin();
      // }
    }
    return this.handleRequest(request, next);
  }
  redirectToLogin() {
    console.log("redirect");

    localStorage.clear();
    this.router.navigate(["login"]);
    // window.location.reload();
  }

  handleRequest(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let customReq;
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    console.log("request---------------------------", request);
    if (request?.url.includes("http://206.189.119.157:7010/api/")) {
      customReq = request.clone({
        headers: request.headers.set("x-auth", token),
      });
    } else if (request?.url.includes("https://fcm.googleapis.com/fcm/send")) {
      customReq = request.clone({
        setHeaders: {
          Authorization:
            "key=AAAAI72R33k:APA91bGDir77sj44RESQ2fNZjTSV6HH3OcJvzd5cd1y6joUTvL-M6l10Y1l9XBkipQ8nlzK7eiNoxRBXp0QGPuQlddGMc8oA97EyTIwIgex4Yoj5o4b5G2DLvrS-IF3VpkatIuNPxrjb",
          "Content-Type": "application/json",
        },
      });
    } else {
      customReq = request.clone({
        headers: request.headers.set("Authorization", token),
      });
    }

    return next.handle(customReq).pipe(
      tap(
        (ev: HttpEvent<any>) => {
          console.log("redirect", ev);
          if (ev instanceof HttpResponse) {
            if (ev.body.status === 401) {
              this.redirectToLogin();
            } else {
              return ev;
            }
          }
        },
        (err: any) => {
          console.log('api err -----------> ', err)
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.redirectToLogin();
            }
          }
        }
      )
    );
  }
}
