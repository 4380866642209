<router-outlet>
  <app-spinner></app-spinner>
</router-outlet>
<!-- <p-messages [(value)]="msgs"></p-messages> -->
<p-toast position="top-right" class="top"></p-toast>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
