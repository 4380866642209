import {
  Component,
  Input,
  forwardRef,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  OnChanges,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
@Component({
  selector: "app-searchable-dorpdown",
  templateUrl: "./searchable-dorpdown.component.html",
  styleUrls: ["./searchable-dorpdown.component.scss"],
})
export class SearchableDorpdownComponent implements OnChanges {
  list = [];
  temp_list = [];
  keyword = "";
  _img: any;
  _label: any;
  _uid: any;
  @Output() afterChange = new EventEmitter();
  @ViewChild("input", { static: false }) input: ElementRef;
  @Input("size") size;
  @Input("items") set items(value) {
    this.list = value;
    this.temp_list = value;
    console.log("list", this.list);
  }
  @Input("img") img;
  @Input("label") label;
  @Input("uid") uid;
  @Output("sendSelectedUser") sendSelectedUser = new EventEmitter<any>();

  onChange: any = () => {};
  onTouch: any = () => {};
  value: any = "Select";
  shown = false;
  constructor(private ele: ElementRef) {}
  ngOnChanges() {
    this._label =
      typeof this.label !== "undefined" && this.label !== ""
        ? this.label
        : "name";
    this._img =
      typeof this.img !== "undefined" && this.img !== "" ? this.img : "img";
    this._uid =
      typeof this.uid !== "undefined" && this.uid !== "" ? this.uid : "id";
    this.value = "Select";
  }
  writeValue(value) {
    if (value) {
      this.temp_list.map((x) => {
        if (x[this._uid] == value) {
          this.value = x[this._label];
        }
      });
    }
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  search(e) {
    const val = e.toLowerCase();
    const temp = this.temp_list.filter((x) => {
      if (x["userFullName"].toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.list = temp;
  }
  select(item) {
    this.onChange(item);
    this.value = item["userFullName"];
    this.shown = false;
    this.afterChange.emit(item);
    this.sendSelectedUser.emit(item);
  }
  show() {
    this.shown = this.shown ? false : true;
    console.log(this.shown);
    if (!this.list?.length) {
      this.list = this.temp_list;
    }
    console.log(this.list);
    console.log("temp", this.temp_list);
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 200);
  }
}
