<div
  class="search-dropdown"
  [ngClass]="{ open: shown, small: size == 'small' }"
>
  <div class="search-dropdown__btn" (click)="show()">
    <span>{{ value | titlecase }}</span>
  </div>
  <div class="search-dropdown__dropdown">
    <input
      class="search-dropdown__dropdown__search"
      type="text"
      placeholder="Search"
      [(ngModel)]="keyword"
      (keyup)="search(keyword)"
      #input
    />
    <div class="search-dropdown__dropdown__scroller">
      <div *ngIf="list?.length">
        <div
          class="search-dropdown__dropdown__item"
          *ngFor="let item of list"
          (click)="select(item)"
        >
          {{ item?.userFullName | titlecase }}
        </div>
      </div>
      <div class="search-dropdown__dropdown__item" *ngIf="!list?.length">
        No Result Found
      </div>
    </div>

    <!-- <div class="search-dropdown__dropdown__msg" *ngIf="!list?.length">
        No Result Found
      </div> -->
  </div>
</div>
